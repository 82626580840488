import React from "react"
import Helmet from "react-helmet"
import { Container } from "reactstrap"

const NotFoundPage = () => (
  <section className="page-404 section-lg">
  <Helmet>
    <meta charSet="utf-8" />
    <title>ERROR 404</title>
    <link rel="canonical" href="" />
  </Helmet>
    <Container>
      <header>
        <h1>404</h1>
        <h2>NOT FOUND</h2>
      </header>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </section>
)

export default NotFoundPage
